import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, formValueSelector, getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { graphql, compose } from 'react-apollo';
import { Redirect } from 'react-router-dom';
import { get, indexOf } from 'lodash';
import moment from 'moment';
import classnames from 'classnames';
import { toast } from 'react-toastify';
import Loading from '../Loading';
import ApplicationKidsCafeStepOne from './ApplicationKidsCafeStepOne';
import ApplicationStatusPending from '../ApplicationStatusPending';
import withProgramsLayout from '../../helpers/withProgramsLayout';
import removeTypename from '../../helpers/removeTypename';
import getErrorMessage from '../../helpers/getErrorMessage';
import { setUserAction } from '../../redux';
import {
  educatorUserQuery,
  configKidsCafeQuery,
  saveKidsCafeApplicationMutation,
  applicationKidsCafeQuery,
  userApplicationsKidsCafeQuery,
} from '../../apollo';


class ApplicationKidsCafe extends Component {
  steps = {
    '/kids-cafe/application/step-1': {
      step: 1,
      component: ApplicationKidsCafeStepOne,
    },
    '/kids-cafe/application/status': {
      step: 2,
      component: ApplicationStatusPending,
    },
  }

  getNextStep = (applicationKidsCafeId) => {
    const { match } = this.props;
    const applicationSteps = Object.keys(this.steps);
    const currentIndex = indexOf(applicationSteps, `/kids-cafe/application/${match.params.step}`);
    const stepsToCheck = applicationSteps.slice(currentIndex, applicationSteps.length + 1);
    let nextStep = stepsToCheck.find(step => (
      step !== `/kids-cafe/application/${match.params.step}`
    ));

    if (applicationKidsCafeId) {
      nextStep = `${nextStep}/${applicationKidsCafeId}`;
    }
    return nextStep;
  }

  saveApplication = async ({
    draft,
    applicationKidsCafeId,
    type,
    new: isNew,
    site,
    forProfit,
    participatedAgriculture,
    agricultureSponsor,
    childcareLicense,
    hasWifi,
    hasRecordingDevice,
    hasStorage,
    receivesDeliveries,
    isChildcareCenter,
    hasMicrowave,
    isTexasRisingStar,
    isFederalParticipant,
    isOpenEnrolledYear,
    hasInfantsEnrolled,
    hasAfterSchool,
    startDate,
    endDate,
    operatingHoursStartTime,
    operatingHoursEndTime,
    gateCode,
    numExpectedParticipants,
    numExpectedParticipantsBreakfast,
    numExpectedParticipantsBrunch,
    numExpectedParticipantsLunch,
    numExpectedParticipantsBogoLunch,
    numExpectedParticipantsSnack,
    numExpectedParticipantsSupper,
    participantAgeRanges,
    daysForService,
    interestBreakfast,
    interestBrunch,
    interestLunch,
    interestBogoLunch,
    interestSnack,
    interestSupper,
    breakfastStartTime,
    breakfastEndTime,
    brunchStartTime,
    brunchEndTime,
    lunchStartTime,
    lunchEndTime,
    bogoLunchStartTime,
    bogoLunchEndTime,
    snackStartTime,
    snackEndTime,
    supperStartTime,
    supperEndTime,
    enrichmentPrograms,
    closureDates,
    referredBy,
    agreeToReimbursement,
    agreeToDocumentation,
    certififedVerification,
    specialDeliveryNotes,
    earliestDeliveryTime,
    fieldTrips,
    nameSign,
    agreeToProgramPurpose,
    siteType
  }) => {
    const {
      dispatch,
      client: { mutate, query },
    } = this.props;

    const variables = {
      draft,
      applicationKidsCafeId,
      type,
      new: isNew,
      site: {
        ...site,
        primaryPointOfContact: {
          ...site.primaryPointOfContact,
          type: 'PRIMARY_POINT_CONTACT',
          isSupervisor: site.primaryPointOfContact.isSupervisor? site.primaryPointOfContact.isSupervisor: false
        },
        secondaryPointOfContact: {
          ...site.secondaryPointOfContact,
          type: 'SECONDARY_POINT_CONTACT',
          isSupervisor: site.secondaryPointOfContact.isSupervisor? site.secondaryPointOfContact.isSupervisor: false
        },
      },
      forProfit,
      participatedAgriculture: false,
      agricultureSponsor,
      childcareLicense,
      hasWifi,
      hasRecordingDevice,
      hasStorage,
      receivesDeliveries,
      isChildcareCenter: false,
      hasMicrowave,
      isTexasRisingStar,
      isFederalParticipant,
      isOpenEnrolledYear,
      hasInfantsEnrolled,
      hasAfterSchool,
      startDate,
      endDate,
      operatingHoursStartTime,
      operatingHoursEndTime,
      gateCode,
      numExpectedParticipants,
      numExpectedParticipantsBreakfast,
      numExpectedParticipantsBrunch,
      numExpectedParticipantsLunch,
      numExpectedParticipantsBogoLunch,
      numExpectedParticipantsSnack,
      numExpectedParticipantsSupper,
      participantAgeRanges,
      daysForService,
      interestBreakfast,
      interestBrunch,
      interestLunch,
      interestBogoLunch,
      interestSnack,
      interestSupper,
      breakfastStartTime,
      breakfastEndTime,
      brunchStartTime,
      brunchEndTime,
      lunchStartTime,
      lunchEndTime,
      bogoLunchStartTime,
      bogoLunchEndTime,
      snackStartTime,
      snackEndTime,
      supperStartTime,
      supperEndTime,
      enrichmentPrograms,
      closureDates,
      referredBy,
      agreeToReimbursement,
      agreeToDocumentation,
      certififedVerification,
      specialDeliveryNotes,
      earliestDeliveryTime,
      fieldTrips,
      agreeToProgramPurpose,
      nameSign,
      siteType
    };

    try {
      const { data } = await mutate({
        mutation: saveKidsCafeApplicationMutation,
        variables: { input: { ...removeTypename(variables) } },
        refetchQueries: [{
          query: userApplicationsKidsCafeQuery,
        }],
      });

      const { data: { educatorUser } } = await query({
        query: educatorUserQuery,
        fetchPolicy: 'network-only',
      });

      if (data.saveKidsCafeApplication && educatorUser) {
        if (!draft) {
          dispatch(setUserAction(educatorUser));
          dispatch(push(this.getNextStep(data.saveKidsCafeApplication.applicationKidsCafeId)));
          toast('Save Successful');
        } else {
          dispatch(setUserAction(educatorUser));
          dispatch(push('/'));
          toast('Draft Saved');
        }
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  saveAsDraft = async (values) => {
    try {
      await this.saveApplication({
        draft: true,
        ...values,
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      if (errorMessage) toast(errorMessage);
      throw (error);
    }
  };

  render() {
    const {
      match,
      data,
      configKidsCafe,
      user,
      adminView,
      program: {
        programUrl,
      },
    } = this.props;

    if (
      (data && data.loading) || configKidsCafe.loading
    ) return <Loading />;

    //
    // Kids Cafe Application Redirect Logic
    //
    if (adminView) {
      if (
        (data && data.error)
        || (data && !data.applicationKidsCafe)
        || configKidsCafe.error
      ) {
        return <Redirect to={`${programUrl}/sites`} />;
      }
      if (
        (data && data.applicationKidsCafe && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/sites`} />;
      }
    } else {
      // If error loading application or application config re-route user
      if (
        (data && data.error)
        || (data && !data.applicationKidsCafe)
        || configKidsCafe.error
      ) {
        return <Redirect to="/login" />;
      }
      // If no associated application route to the start page
      if (
        user.applicationKidsCafeRequired
        && !match.params.step
      ) {
        return <Redirect to={`${programUrl}/start`} />;
      }
      // If required to fill out application route to the first step
      if (
        user.applicationKidsCafeRequired
        && user.applicationKidsCafeRequiredId
        && !data && false
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationKidsCafeRequiredId}`} />;
      }
      // If attempt to view an input step of the application while
      // it's pending approval, route to the status page
      /*if (
        (data
          && data.applicationKidsCafe
          && data.applicationKidsCafe.status === 'PENDING'
          && match.params.step !== 'status')
      ) {
        return <Redirect to={`${programUrl}/application/status/${match.params.id}`} />;
      }*/
      // If attempt to view the status step of the application while
      // it's in progress, route to the first step
      if (
        (data
          && data.applicationKidsCafe
          && data.applicationKidsCafe.status === 'IN_PROGRESS'
          && match.params.step === 'status')
      ) {
        return <Redirect to={`${programUrl}/application/step-1/${user.applicationKidsCafeRequiredId}`} />;
      }
    }

    const activeStep = get(this.steps, [`${programUrl}/application/${match.params.step}`]);

    if (!activeStep) return <Redirect to="/login" />;
    const { component: ApplicationStep, step } = activeStep;
    const { acceptingApplications, applicationYear } = configKidsCafe.configKidsCafe;
    const readOnly = (data && data.applicationKidsCafe && (data.applicationKidsCafe.status === 'APPROVED')) || adminView || !acceptingApplications;
    const appApplicationYear = get(data, 'applicationKidsCafe.applicationYear', applicationYear);

    return (
      <Fragment>
        <section className={classnames({ 'section-content': activeStep.step !== 2 }, { 'section-begin-application': activeStep.step === 2 })}>
          <div className="_1300-container">
            <div className={classnames({ 'program-form-block w-form': activeStep.step !== 2 }, { 'start-flex': activeStep.step === 2 })}>
              {activeStep.step === 1 && <h2>{`Kids Cafe Program Application`}</h2>}
              <ApplicationStep
                {...this.props}
                submit={this.saveApplication}
                saveAsDraft={this.saveAsDraft}
                readOnly={readOnly}
                acceptingApplications={acceptingApplications}
                step={step}
              />
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const selector = formValueSelector('applicationForm');
  const currentApplication = get(ownProps.data, 'applicationKidsCafe');
  const startDate = currentApplication && moment(currentApplication.startDate).format('MM/DD/YYYY');
  const endDate = currentApplication && moment(currentApplication.endDate).format('MM/DD/YYYY');

  console.log(state)
  return {
    adminView: state.user.type === 'ADMIN',
    type: selector(state, 'type'),
    participatedAgriculture: selector(state, 'participatedAgriculture'),
    childcareLicense: selector(state, 'childcareLicense') == 'YES',
    isChildcareCenter: selector(state, 'isChildcareCenter'),
    site: selector(state, 'site'),
    submittedAt: selector(state, 'submittedAt'),
    interestBreakfast: selector(state, 'interestBreakfast'),
    interestBrunch: selector(state, 'interestBrunch'),
    interestLunch: selector(state, 'interestLunch') ,
    interestBogoLunch: selector(state, 'interestBogoLunch'),
    interestSnack: selector(state, 'interestSnack'),
    interestSupper: selector(state, 'interestSupper'),
    formSyncErrors: getFormSyncErrors('applicationForm')(state),
    user: state.user,
    applicationYear: ownProps.configKidsCafe.configKidsCafe ? ownProps.configKidsCafe.configKidsCafe.applicationYear: 2024,
    initialValues: {
      ...currentApplication,
      readProgramAgreement: false,
      startDate,
      endDate,
    },
  };
};

const withData = ComponentToWrap => (
  (otherProps) => {
    const WithData = compose(
      graphql(applicationKidsCafeQuery, {
        name: 'data',
        skip: ({ match }) => !match.params.id,
        options: ({ match }) => ({
          variables: {
            applicationKidsCafeId: match.params.id,
          },
          fetchPolicy: 'network-only',
        }),
      }),
      graphql(configKidsCafeQuery, {
        name: 'configKidsCafe',
      }),
      connect(mapStateToProps),
      reduxForm({
        form: 'applicationForm',
        enableReinitialize: true,
        destroyOnUnmount: false,
        forceUnregisterOnUnmount: true,
      }),
    )(ComponentToWrap);

    return <WithData {...otherProps} />;
  }
);

ApplicationKidsCafe.propTypes = {
  adminView: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  configKidsCafe: PropTypes.object.isRequired,
  data: PropTypes.object,
  program: PropTypes.object.isRequired,
};

ApplicationKidsCafe.defaultProps = {
  data: null,
};

export default withProgramsLayout(withData(ApplicationKidsCafe));
